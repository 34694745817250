<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 198.8 262.3"
    class="h-24 fill-black dark:fill-white"
  >
    <g>
      <g id="Layer_5">
        <g>
          <path
            class="cls-1"
            d="M21.7,227.7h-1.9c-.2,0-.4,0-.5-.2-.1-.1-.2-.2-.3-.4l-1.7-4.3h-8l-1.7,4.3c0,.2-.2.3-.3.4-.1.1-.3.2-.5.2h-1.9l7.2-17.9h2.5l7.2,17.9ZM10,221.1h6.7l-2.8-7.3c-.2-.5-.4-1-.5-1.7,0,.3-.2.7-.3.9,0,.3-.2.5-.3.8l-2.8,7.3Z"
          />
          <path
            class="cls-1"
            d="M44.5,218.8c0,1.3-.2,2.6-.6,3.7-.4,1.1-1,2-1.8,2.8-.8.8-1.7,1.4-2.8,1.8-1.1.4-2.3.6-3.6.6h-6.7v-17.9h6.7c1.3,0,2.5.2,3.6.6,1.1.4,2,1,2.8,1.8.8.8,1.4,1.7,1.8,2.8.4,1.1.6,2.3.6,3.7ZM42,218.8c0-1.1-.1-2.1-.4-3-.3-.9-.7-1.6-1.3-2.2-.5-.6-1.2-1.1-2-1.4-.8-.3-1.7-.5-2.6-.5h-4.3v14h4.3c1,0,1.8-.2,2.6-.5s1.5-.8,2-1.4c.6-.6,1-1.3,1.3-2.2.3-.9.4-1.8.4-3Z"
          />
          <path
            class="cls-1"
            d="M50,209.8h1.9c.2,0,.4,0,.5.2s.2.2.3.4l5.1,12.6c.1.3.2.6.3.9,0,.3.2.7.3,1,0-.4.2-.7.2-1,0-.3.2-.6.3-.9l5-12.6c0-.1.2-.3.3-.4.1-.1.3-.2.5-.2h2l-7.3,17.9h-2.2l-7.3-17.9Z"
          />
          <path
            class="cls-1"
            d="M85.1,209.8v2h-8.6v6h7v1.9h-7v6.1h8.6v2h-11v-17.9h11Z"
          />
          <path
            class="cls-1"
            d="M95.2,209.9c.1,0,.2.2.4.3l10.4,13.5c0-.2,0-.4,0-.6,0-.2,0-.4,0-.6v-12.7h2.1v17.9h-1.2c-.2,0-.4,0-.5,0-.1,0-.3-.2-.4-.3l-10.4-13.5c0,.2,0,.4,0,.6s0,.4,0,.5v12.8h-2.1v-17.9h1.2c.2,0,.4,0,.5,0Z"
          />
          <path
            class="cls-1"
            d="M129.6,209.8v2h-5.8v15.9h-2.4v-15.9h-5.8v-2h14Z"
          />
          <path
            class="cls-1"
            d="M144,225.8c.7,0,1.4-.1,2-.4s1.1-.6,1.5-1c.4-.5.7-1,.9-1.6.2-.6.3-1.3.3-2.1v-10.9h2.4v10.9c0,1-.2,2-.5,2.9-.3.9-.8,1.6-1.4,2.3-.6.6-1.4,1.2-2.2,1.5-.9.4-1.9.6-3,.6s-2.1-.2-3-.6c-.9-.4-1.6-.9-2.2-1.5-.6-.6-1.1-1.4-1.4-2.3-.3-.9-.5-1.8-.5-2.9v-10.9h2.4v10.9c0,.8.1,1.4.3,2.1s.5,1.2.9,1.6.9.8,1.5,1.1c.6.3,1.3.4,2,.4Z"
          />
          <path
            class="cls-1"
            d="M162.9,220.2v7.5h-2.4v-17.9h5.1c1.1,0,2.1.1,2.9.3.8.2,1.5.6,2,1,.5.4.9,1,1.2,1.6.3.6.4,1.3.4,2.1s0,1.2-.3,1.8-.5,1-.9,1.5c-.4.4-.8.8-1.4,1.1-.5.3-1.2.5-1.9.7.3.2.6.4.8.8l5.2,7.1h-2.2c-.4,0-.8-.2-1-.5l-4.7-6.4c-.1-.2-.3-.3-.5-.4-.2,0-.4-.1-.8-.1h-1.8ZM162.9,218.5h2.5c.7,0,1.3,0,1.9-.3.5-.2,1-.4,1.3-.7s.6-.7.8-1.1c.2-.4.3-.9.3-1.4,0-1.1-.4-1.9-1.1-2.4-.7-.5-1.8-.8-3.1-.8h-2.6v6.8Z"
          />
          <path
            class="cls-1"
            d="M192.3,209.8v2h-8.6v6h7v1.9h-7v6.1h8.6v2h-11v-17.9h11Z"
          />
          <path
            class="cls-1"
            d="M30.7,251.9c0,.2.2.4.3.7,0-.2.2-.5.3-.7s.2-.4.3-.6l6.1-11c.1-.2.2-.3.3-.3.1,0,.3,0,.5,0h1.8v17.9h-2.1v-13.2c0-.2,0-.4,0-.6,0-.2,0-.4,0-.6l-6.1,11.2c-.2.4-.5.6-.9.6h-.4c-.4,0-.7-.2-.9-.6l-6.3-11.2c0,.2,0,.4,0,.6s0,.4,0,.6v13.2h-2.1v-17.9h1.8c.2,0,.4,0,.5,0,.1,0,.2.2.3.3l6.2,11c.1.2.2.4.3.6Z"
          />
          <path
            class="cls-1"
            d="M64.3,257.7h-1.9c-.2,0-.4,0-.5-.2-.1-.1-.2-.2-.3-.4l-1.7-4.3h-8l-1.7,4.3c0,.2-.2.3-.3.4-.1.1-.3.2-.5.2h-1.9l7.2-17.9h2.5l7.2,17.9ZM52.6,251.1h6.7l-2.8-7.3c-.2-.5-.4-1-.5-1.7,0,.3-.2.7-.3.9,0,.3-.2.5-.3.8l-2.8,7.3Z"
          />
          <path
            class="cls-1"
            d="M82.1,239.8v2h-5.8v15.9h-2.4v-15.9h-5.8v-2h14Z"
          />
          <path
            class="cls-1"
            d="M101.8,239.8v2h-5.8v15.9h-2.4v-15.9h-5.8v-2h14Z"
          />
          <path
            class="cls-1"
            d="M111.9,250.2v7.5h-2.4v-17.9h5.1c1.1,0,2.1.1,2.9.3.8.2,1.5.6,2,1,.5.4.9,1,1.2,1.6.3.6.4,1.3.4,2.1s0,1.2-.3,1.8-.5,1-.9,1.5-.8.8-1.4,1.1c-.5.3-1.2.5-1.9.7.3.2.6.4.8.8l5.2,7.1h-2.1c-.4,0-.8-.2-1-.5l-4.6-6.4c-.1-.2-.3-.3-.5-.4s-.4-.1-.8-.1h-1.8ZM111.9,248.5h2.5c.7,0,1.3,0,1.9-.3.5-.2,1-.4,1.4-.7s.6-.7.8-1.1c.2-.4.3-.9.3-1.4,0-1.1-.4-1.9-1.1-2.4s-1.8-.8-3.1-.8h-2.7v6.8Z"
          />
          <path
            class="cls-1"
            d="M141.3,239.8v2h-8.6v6h7v1.9h-7v6.1h8.6v2h-11v-17.9h11Z"
          />
          <path
            class="cls-1"
            d="M159,242.6c0,.1-.2.2-.2.3s-.2,0-.3,0-.3,0-.5-.2-.4-.3-.7-.5-.6-.3-1-.5c-.4-.1-.9-.2-1.5-.2s-1,0-1.4.2c-.4.1-.8.3-1,.6s-.5.5-.6.9-.2.7-.2,1.1.1.9.4,1.2c.2.3.6.6,1,.8s.9.4,1.4.6c.5.2,1,.3,1.6.5.5.2,1.1.4,1.6.6.5.2,1,.5,1.4.9.4.3.7.8,1,1.3.2.5.4,1.1.4,1.9s-.1,1.5-.4,2.2-.7,1.3-1.2,1.8c-.5.5-1.1.9-1.9,1.2-.7.3-1.6.4-2.5.4s-2.2-.2-3.2-.6c-1-.4-1.8-1-2.4-1.7l.7-1.1c0,0,.1-.2.2-.2,0,0,.2,0,.3,0,.2,0,.4,0,.6.3.2.2.5.4.8.6.3.2.7.4,1.2.6.5.2,1.1.3,1.8.3s1.1,0,1.5-.2c.4-.2.8-.4,1.1-.7s.6-.6.7-1c.2-.4.2-.8.2-1.3s-.1-1-.4-1.3-.6-.6-1-.9c-.4-.2-.9-.4-1.4-.6-.5-.2-1-.3-1.6-.5-.5-.2-1.1-.4-1.6-.6-.5-.2-1-.5-1.4-.9-.4-.4-.7-.8-1-1.3-.2-.5-.4-1.2-.4-2s.1-1.2.4-1.8c.2-.6.6-1.1,1.1-1.6.5-.5,1-.8,1.7-1.1.7-.3,1.5-.4,2.4-.4s1.9.2,2.7.5c.8.3,1.5.8,2.2,1.4l-.6,1.2Z"
          />
          <path
            class="cls-1"
            d="M177.2,242.6c0,.1-.2.2-.2.3s-.2,0-.3,0-.3,0-.5-.2-.4-.3-.7-.5-.6-.3-1-.5c-.4-.1-.9-.2-1.5-.2s-1,0-1.4.2c-.4.1-.8.3-1,.6s-.5.5-.6.9-.2.7-.2,1.1.1.9.4,1.2c.2.3.6.6,1,.8s.9.4,1.4.6c.5.2,1,.3,1.6.5.5.2,1.1.4,1.6.6.5.2,1,.5,1.4.9.4.3.7.8,1,1.3.2.5.4,1.1.4,1.9s-.1,1.5-.4,2.2-.7,1.3-1.2,1.8c-.5.5-1.1.9-1.9,1.2-.7.3-1.6.4-2.5.4s-2.2-.2-3.2-.6c-1-.4-1.8-1-2.4-1.7l.7-1.1c0,0,.1-.2.2-.2,0,0,.2,0,.3,0,.2,0,.4,0,.6.3.2.2.5.4.8.6.3.2.7.4,1.2.6.5.2,1.1.3,1.8.3s1.1,0,1.5-.2c.4-.2.8-.4,1.1-.7s.6-.6.7-1c.2-.4.2-.8.2-1.3s-.1-1-.4-1.3-.6-.6-1-.9c-.4-.2-.9-.4-1.4-.6-.5-.2-1-.3-1.6-.5-.5-.2-1.1-.4-1.6-.6-.5-.2-1-.5-1.4-.9-.4-.4-.7-.8-1-1.3-.2-.5-.4-1.2-.4-2s.1-1.2.4-1.8c.2-.6.6-1.1,1.1-1.6.5-.5,1-.8,1.7-1.1.7-.3,1.5-.4,2.4-.4s1.9.2,2.7.5c.8.3,1.5.8,2.2,1.4l-.6,1.2Z"
          />
        </g>
      </g>
      <g id="Layer_2">
        <path
          class="cls-1"
          d="M56.7.5s-1.2-1.2-2.7,0L16.7,90.3s-6,12.4-16.7,12.4c0,.8,0,1.7,0,1.7h38.1v-1.7s-20.7,0-16.6-13c1.6-5.2,7-18.4,12.8-32.5h38.9l6.6,14.1,6.2-9.7L56.7.5ZM35.7,53.9c8.3-19.9,17.1-40.3,17.1-40.3l18.8,40.3h-35.9Z"
        />
        <line
          class="stroke-black stroke-2 dark:stroke-white"
          x1="131.8"
          y1="26.8"
          x2="29"
          y2="173.4"
        />
        <path
          class="cls-1"
          d="M84.5,135l5.2-5.7v41s.3,8.5,13.4,8.5v2.1h-31.5v-2.1s12.9-.1,12.9-8.5v-35.3Z"
        />
        <path
          class="cls-1"
          d="M99.5,113.5l31.2,66.2h2.5l38.7-88.6,2.7,81.1s1.8,6.6-13.4,6.6c0,1.2,0,2.1,0,2.1h37.5v-2.1s-14.5.8-15.3-7-3.5-82.1-3.5-82.1c0,0-1.5-9.7,15.5-9.7,0-1.2.2-2.1.2-2.1h-19.5c-1.4,0-3.3,1.4-5.8,7s-35.2,80.9-35.2,80.9l-28.8-61.4-6.8,9.1Z"
        />
      </g>
    </g>
  </svg>
</template>
